/* src/Component/LandingPage.css */

#page-wrapper {
    font-family: Roboto, sans-serif;
    /* background-color: #170026; */
    background-color: transparent;
    

}

/* Fixed, Transparent Navbar */
header {
    position: fixed; /* Keeps the header fixed at the top */
    top: 0; /* Aligns the header at the very top */
    left: 0; /* Ensures it starts at the left edge */
    width: 100%; /* Makes the header span the full width of the page */
    background: rgba(255, 255, 255, 0); /* Transparent background */
    z-index: 100; /* Ensures the header is above other content */
    padding-bottom: 10px;
}

/* Inner layout of header */
.header-inner {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e   /* Dark blue */
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px 20px;
    width: 100%;
}

/* Other existing styles remain the same */

.logo-container {
    display: flex;
    align-items: center;
    z-index: 2;
}

.logoheader {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.name {
    color: #fc4100;
    font-size: 24px;
    font-weight: bold;
}

.nav {
    flex: 1;
}

.nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: center;
}

.nav ul li {
    margin: 0 15px;
}

.nav ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
    position: relative;
}

.nav ul li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #fc4100;
    transition: width 0.3s ease;
}

.nav ul li a:hover::after {
    width: 100%;
}

.auth-buttons {
    display: flex;
    gap: 10px;
    z-index: 2;
}

.auth-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
    border: 1px solid #ffffff;
}

.login-btn, .signup-btn, .guest-btn {
    background-color: transparent;
    color: #ffffff;
    
}

.login-btn:hover, .guest-btn:hover, .signup-btn:hover {
    background-color: #ffc55a;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid  #fc4100;
    color: #fc4100;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    color: #fc4100;
    cursor: pointer;
    z-index: 3;
    transition: transform 0.3s ease;
}

.menu-toggle.active {
    transform: rotate(90deg);
}


.dark-mode-toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 29px;
    margin: auto auto; /* Centers horizontally if the parent has a defined width */
  }
  /* Ensure the parent container allows for vertical centering */
.auth-buttons {
    display: flex;
    align-items: center;
    height: 100%; /* Make sure the parent has a height */
  }
  
  /* Adjust the toggle's position within auth-buttons */
  .auth-buttons .dark-mode-toggle {
    margin-left: 20px; /* Keep some space from the previous element */
  }
  
  .toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
  }
  
  .toggle-switch {
    position: absolute;
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  /* .toggle-checkbox:checked + .toggle-label {
    background-color: #2196F3;
  } */
  
  .toggle-checkbox:checked + .toggle-label .toggle-switch {
    transform: translateX(20px);
  }
  
  .toggle-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    z-index: 1;
  }
@media (max-width: 1038px) {
    .header-inner {
        flex-wrap: wrap;
    }

    .nav {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #00215e;   /* Dark blue */
        padding: 20px;
        display: none;
        z-index: 1;
    }

    .nav.active {
        display: block;
        animation: slideDown 0.3s ease-out;
    }

    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
    }

    .nav ul li {
        margin: 15px 0;
    }

    .auth-buttons {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
    }

    .menu-toggle {
        display: block;
    }
}

@media (max-width: 480px) {

    .name {
        font-size: 20px;
    }

    .auth-buttons button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .auth-buttons {
        flex-direction: row;
        align-items:end;
    }
    body.light-mode .nav {
        background-color: #ffffff;
        color: #000;
    }
}


/* ================================================================================================================================ */
#afterheader1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(
        135deg, 
        rgb(0, 33, 94) 0%,    /* Semi-transparent dark blue */
        rgb(44, 78, 128) 50%, /* Semi-transparent darker blue */
        rgb(0, 33, 94) 100%   /* Semi-transparent dark blue */
    );
    padding: 20px;
    position: relative;
    overflow: hidden;
}

#afterheader1::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle at 50% 50%, #a34604db, transparent 70%);
    animation: spin 10s linear infinite;
    z-index: 0;
    pointer-events: none; /* Make it non-interactive */
}

#afterheader1::after {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 50%;
    animation: float 6s ease-in-out infinite;
    top: 10%;
    left: 80%;
    z-index: 1;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
}

/* Flex container for avatar and text */
.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background-color: #00ffcc; */
}

/* Left section (text) styling */
.left {
    flex: 1;
    padding-right: 0px;
    color: #fc4100;
    text-align: left;
    padding-left: 4%;
    /* background-color: #1a1a1a; */
}

.left h2 {
    font-size: 3.5vw;
    white-space: nowrap;
    color: #fc4100;
    -webkit-text-stroke: 2px #000000; /* Black outline */
    text-shadow: 6px 6px 15px rgba(0, 0, 0, 0.7); /* Stronger, larger shadow */
    font-weight: bold; /* Make the text appear bolder */
    transition: transform 0.3s ease, letter-spacing 0.1s ease;
    margin-bottom: 10px;
    z-index: 2;
}

.left p {
    font-size: 1.5em;
    color: #ffffff;
    -webkit-text-stroke: 1px #000000; /* Black outline */
    max-width: 600px;
    padding-top: 0;
    margin-top: 0;
    z-index: 2;
    text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.7); /* Bigger and stronger shadow */
    font-weight: bold; /* Make paragraph text bold */
}


.get-started {
    padding: 12px 24px;
    border: 1px solid white;
    background-color: transparent;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
}

.get-started:hover {
    background-color: #ffc55a;
    color: #fc4100;
    transform: translateY(-3px);
}

.get-started:active {
    background-color: #007070;
}

/* Avatar container and image */
.avatar-containerr {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.avatar-image {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 100%;
    position: relative;
    bottom: -5vh;
}

/* Responsive Styling for Medium Screens (1024px) */
@media (max-width: 1024px) {
    #afterheader1 {
        padding: 15px;
    }

    .content {
        flex-direction: column;
        align-items: center;
    }

    .left {
        text-align: center;
        padding-top:250px;
        width: 50%;
    }

    .left h2 {
        font-size: 40px;
    }

    .avatar-containerr {
        margin-top: 20px;
        width: 100%;
    }
}

/* For Small Screens (768px) */
@media (max-width: 768px) {
    #afterheader1 {
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }

    .left {
        text-align: center;
        padding: 10px;
        width: 60%;
    }

    .left h2 {
        font-size: 35px;
    }

    .left p {
        font-size: 1.1em;
    }

    .avatar-image {
        width: 60%;
        margin-top: 20px;
    }

    .get-started {
        padding: 10px 20px;
        font-size: 1em;
    }
}

/* Extra Small Screens (568px) */
@media (max-width: 568px) {
    .content {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }
    .left{
        width: 100%;
    }

    .left h2 {
        font-size: 30px;
    }

    .left p {
        font-size: 1em;
    }

    .avatar-image {
        width: 100%;
        margin-top: 0px;
    }

    .get-started {
        padding: 8px 16px;
        font-size: 0.9em;
    }
}


/* ===================================================================================================================================================================== */
.gender-equality-section {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e   /* Dark blue */
      );
    align-items: center;
    justify-content: center;
  }
  
  .container {
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
/* Image container styles */
.image-containeer {
    flex: 1;
    margin-top: 5px;
    width: 100%; /* Full width */
    height: auto; /* Height adjusts based on content */
    display: flex; /* Flexbox for centering image */
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Hide overflow of the image */
}

/* Ensure image fits within container */
.equality-image {
    max-width: 100%; /* Prevent overflow */
    max-height: 100%; /* Ensure height does not exceed container */
    object-fit: contain; /* Maintain aspect ratio */
}
  
  .content-container {
    flex:1;
    width: 100%;
    text-align: justify;
    padding-right: 100px;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #fc4100;
    margin-bottom: 1rem;
    letter-spacing: normal; /* Ensure no extra letter spacing */
    word-spacing: normal;   /* Ensure no extra word spacing */
    line-height: 1.2;       /* Adjust line height */
    text-align: left;       /* Ensure text is not justified */
    white-space: normal;    /* Prevent any unusual white-space formatting */
  }
  
  
  
  .genderdescription {
    font-size: 1.3rem;
    color: #ffffff;
    margin-bottom: 1rem;
    letter-spacing: normal; /* Ensure no extra letter spacing */
    word-spacing: normal;   /* Ensure no extra word spacing */
    line-height: 1.2;       /* Adjust line height */
    text-align: left;       /* Ensure text is not justified */
    white-space: normal;    /* Prevent any unusual white-space formatting */
    padding-bottom: 40px;
  }

  .profile-section {
    display: flex;
    align-items: center;
  }
  
  .profile-images {
    display: flex;
    margin-right: 1rem;
  }
  
  .profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: #fc4100;
    margin-right: -0.75rem;
  }
  
  .profile-text {

    color: #ffffff;
    max-width: 300px;
  
  }
 
@media (min-width: 1024px) {
    .container {
        padding: 20px;
        flex-direction: row;
        align-items: flex-start;
        gap: 4rem;
        display: flex;
        justify-content: center; /* Centers horizontally */
        align-items: center; 
    }
    .profile-section {
        justify-content: center; /* Center profile section on small screens */
        margin-top: 1rem; /* Add spacing above */
        align-content: ;
    }

    .profile-img {
        width: 40px; /* Smaller profile image */
        height: 40px; /* Smaller profile image */
    }

    .profile-text {
        font-size: 1.05rem;      /* Text size */
        letter-spacing: normal;  /* Ensure no extra letter spacing */
        word-spacing: normal;    /* Ensure no extra word spacing */
        line-height: 1.5;        /* Adjust line height for readability */
        text-align: left;        /* Ensure no justification or alignment issues */
        white-space: normal;     /* Prevent unusual white-space handling */
        margin: 0;               /* Remove any extra margins */
        padding: 0;              /* Remove any extra padding */
        display: inline-block;   /* Ensure it's treated like inline text */
        text-transform: none;    /* Ensure no text case transformations */
        word-break: normal;      /* Avoid breaking words unusually */
      }
      
      

}
@media (max-width: 1024px) {
    .container {
        padding: 20px;
    }
    .content-container {
        padding-right: 0px;
      }
}

@media (max-width: 768px) {
    .image-container {
        max-width: 100%; /* Ensure full width */
        background-color: red;
    }

    .equality-image {
        width: 100%; /* Image takes full width */
        height: 100%; /* Maintain aspect ratio */
    }

    .container {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: center;
    }

    .profile-section {
        justify-content: center; /* Center profile section on small screens */
        margin-top: 1rem; /* Add spacing above */
    }

    .profile-img {
        width: 40px; /* Smaller profile image */
        height: 40px; /* Smaller profile image */
    }


}


@media (max-width: 520px) {
    .content-container {
        width: 100%;
    }

    .title {
        padding: 0;
        margin: 0;
        width: 100%; /* Make the title take up 100% width */
        margin: 0; /* Ensure no extra margin */
        padding: 0; /* Adjust padding as necessary */
        font-size: 1.6rem;
    }

    .genderdescription {
        width: 100%; /* Ensure the description takes up 100% width */
        margin: 0; /* Remove margins */
        padding: 0; /* Adjust padding as necessary */
        font-size: 1.2rem;
    }

    .profile-text {
        display: none;
    }
}

/* ================================================================================================================================ */
/* Our Mission Section */
#our-mission {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e   /* Dark blue */
      );
    color: white;
    padding: 50px 20px;
    text-align: center;
}

#our-mission h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #fc4100;
}

#our-mission p {
    max-width: 1000px;
    margin: 0 auto;
    line-height: 1.6;
    text-align: justify;
    font-size: 1.25em;
}

@media (max-width: 768px) {
    #our-mission p {
        font-size: 0.9em;
        padding-left: 10px;
        padding-right: 10px;
    }
}
/* ================================================================================================================================ */
#benefits {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e   /* Dark blue */
      );
    color: white;
    padding: 50px 0px;
    text-align: center;
    overflow: hidden;
}

.benefits-container-outside h2 {
    margin-top: 30px;
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #fc4100;
}
h3 {
    margin-top: 30px;
    font-size: 1.8em;
    margin-bottom: 30px;
    color: #fc4100;
}

.benefits-scroll:hover {
    animation-play-state: paused;
}

.benefits-container {
    overflow: hidden;
    padding-top: 30px;
}

.benefits-scroll {
    display: flex;
    width:min-content;
    animation: scrollRight 30s linear infinite;
}

.benefit-item {
    flex: 0 0 200px;
    margin: 0 0px;
    text-align: center;
}

.benefit-icon {
    font-size: 3em;
    color: #00ffcc;
    margin-bottom: 10px;
}
/* In Benefits.css */
.benefit-icon img {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    object-fit: contain;
}

@keyframes scrollRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.about-video {
    max-width: 80%;
    margin: 0 auto;
}

.about-video video {
    width: 100%;
    border-radius: 30px;
}

@media (max-width: 768px) {
    .benefits-scroll {
        width:min-content;
        animation: scrollRight 10s linear infinite;
    }
    .about-video {
        max-width: 90%;
    }
    .about-video video {
        border-radius: 20px;
    }
}
@media (max-width: 568px) {
    .benefits-scroll {
       
        width:min-content;
        animation: scrollRight 5s linear infinite;
    }
    .about-video {
        max-width: 95%;
    }
    .about-video video {
        border-radius: 10px;
    }
}

/* ================================================================================================================================ */
.our-approach {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e   /* Dark blue */
      );
    padding: 4rem 2rem;
    text-align: center;
    
  }
  
  .approach-title {
    font-size: 2.5rem;
    color: #fc4100;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .approach-description {
    font-size: 1rem;
    color: #ffffff;
    max-width: 1000px;
    margin: 0 auto 3rem;
    line-height: 1.5;
    text-align: justify;
  }
  
  .approach-cards {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
}

.approach-card {
    background-color: #2a2a2a7f;
    border-radius: 15px; /* Rounded corners */
    overflow: hidden;
    width: 350px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.811); /* Enhanced shadow */
    transition: transform 0.4s ease, box-shadow 0.4s ease, background-color 0.4s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    background-color: #1a1a1a; /* Dark background */
}


.approach-card:hover {
    transform: scale(1.07); /* Slight zoom on hover */
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4); /* Deeper shadow effect on hover */
    color: #ffffff;
}

.approach-card:hover::before {
    opacity: 1; /* More visible gradient on hover */
}

.card-image-container {
    height: 350px;
    overflow: hidden;
    position: relative;
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease, filter 0.4s ease;
    filter: brightness(0.85);
}

.approach-card:hover .card-image {
    transform: scale(1.1);
    filter: brightness(1); /* Image brightens on hover */
}

.card-title {
    font-size: 1.35rem;
    color: #fc4100;
    margin: 1.5rem 0 0.5rem;
    font-weight: 700;
    padding: 0 1.5rem;
    text-align: left;
    letter-spacing: 0.5px; /* Professional spacing */
    text-transform: uppercase; /* Uppercase for emphasis */
}

.card-description {
    font-size: 1rem;
    color: #fffcfc;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.8;
    flex-grow: 1;
    text-align: left;
    opacity: 0.85;
}

.card-link {
    display: inline-block;
    color: #fc4100;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    transition: color 0.4s ease, transform 0.4s ease;
    text-align: left;
    padding: 0 1.5rem;
    position: relative;
}

.card-link:hover {
    color: #fc4100;
    transform: translateX(5px); /* Subtle movement effect */
}

.card-link:hover::after {
    width: 100%;
}

.card-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #1616aa;
    transition: width 0.4s ease;
}



/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .approach-cards {
        gap: 1.5rem; /* Adjusted gap for smaller screens */
    }
    
    .approach-card {
        width: calc(50% - 0.75rem); /* Adjusted width for smaller screens */
    }
}

@media (max-width: 768px) {
    .approach-cards {
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center cards */
    }

    .approach-card {
        width: 90%; /* Full width for mobile */
        max-width: 280px; /* Set max width to make cards smaller */
        height: auto; /* Allow height to adjust based on content */
    }

    .card-image-container {
        height: auto; /* Further reduced height for smaller screens */
    }

    .card-title {
        font-size: 1.15rem; /* Further adjust title size */
    }

    .card-description {
        font-size: 0.8rem; /* Further adjust description size */
    }

    .card-link {
        font-size: 0.8rem; /* Adjust link size */
    }
}


/* ================================================================================================================================ */
/* Features Section */
#features {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e    /* Dark blue */
    );
    color: white;
    padding: 50px 20px;
    text-align: center;
}

#features h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #fc4100;
}

#features > p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-card {
    background-color: #2a2a2a;
    border-radius: 10px;
    padding: 20px;
    text-align: left; /* Left align text inside the card */
    padding-bottom: 10px;
}

.feature-card-header {
  
    align-items: center; /* Align icon and text */
    margin-bottom: 10px; /* Add spacing after the icon-heading row */
    text-align: left; /* Left align text inside the card */
}

.feature-icon {
    font-size: 2em;
    color: #00ffcc;
    margin-right: 10px; /* Reduced space between the icon and heading */
}

.feature-card h3 {
    font-size: 1.5em;
    margin: 0; /* Remove margin to keep heading aligned with icon */
}

.feature-card p {
    font-size: 1em;
    line-height: 1.6;
    text-align: justify;
}

/* For tablets and smaller screens (768px and below) */
@media (max-width: 768px) {
    #features h2 {
        font-size: 2em;
    }

    #features > p {
        font-size: 1.1em;
    }

    .feature-card {
        padding: 15px;
    }

    .feature-icon {
        font-size: 2em;
    }

    .feature-card h3 {
        font-size: 1.4em;
    }

    .feature-card p {
        font-size: 1em;
    }
}

/* For mobile screens (480px and below) */
@media (max-width: 480px) {
    #features h2 {
        font-size: 1.5em;
    }

    #features > p {
        font-size: 1em;
    }

    .features-grid {
        grid-template-columns: 1fr; /* Single column layout for mobile */
    }

    .feature-card {
        padding: 10px;
    }

    .feature-icon {
        font-size: 1.8em;
    }

    .feature-card h3 {
        font-size: 1.2em;
    }

    .feature-card p {
        font-size: 0.9em;
    }
}


/* ========================================================================================================================================= */
#testimonials {
    background: linear-gradient(
        to right, 
        #00215e,   /* Dark blue */
        #2c4e80,   /* Darker blue */
        #00215e    /* Dark blue */
    );
    color: white; /* Text color */
    padding: 40px 0px; /* Reduced padding for the section */
    text-align: center; /* Center-align text */
    overflow: hidden; /* Hide overflow */
}

.testimonial-title {
    font-size: 2rem; /* Adjusted title size */
    color: #fc4100;
    margin-bottom: 20px; /* Reduced margin bottom */
    font-weight: 600;
}

.testimonial-container {
    width: 100%; /* Full width for container */
    overflow: hidden; /* Hide overflow */
}

.testimonial-row {
    display: flex; /* Use flexbox for row */
    animation: scroll 30s linear infinite; /* Animation for scrolling */
    width: min-content; /* Min width for row */
    padding-bottom: 20px; /* Reduced space below the row */
}

.testimonial-row.top {
    animation-direction: normal; /* Normal animation direction */
}

.testimonial-row.bottom {
    animation-direction: reverse; /* Reverse animation direction */
}

.testimonial-card {
    flex: 0 0 280px; /* Reduced fixed width for the card */
    border-radius: 10px; /* Rounded corners */
    padding: 10px; /* Reduced padding inside the card */
    margin: 10px 10px; /* Reduced margin around the card */
    background-color: #2a2a2a; /* Background color */
    border: 1px solid #333; /* Dark mode border */
    box-shadow: 0 8px 22px rgba(24, 109, 109, 0.812); /* Dark mode shadow */
    padding-bottom: 0;
}

.testimonial-header {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Reduced space below the header */
}

.testimonial-image {
    width: 60px; /* Adjusted width for the image */
    height: 60px; /* Adjusted height for the image */
    border-radius: 50%; /* Round image shape */
    object-fit: cover; /* Cover the area */
    display: block; /* Block display for image */
    margin-right: 10px; /* Reduced space between image and text */
}

.testimonial-info {
    text-align: left; /* Align text to the left */
}

.testimonial-card h3 {
    margin: 0; /* Remove margin for better alignment */
    font-size: 1.2em; /* Adjusted heading size */
}

.testimonial-card .title {
    color: #666; /* Color for the title */
    margin: 0; /* Remove margin for better alignment */
    font-size: small; /* Title font size */
}

.rating {
    text-align: center; /* Center-align the rating */
    margin-bottom: 3px; /* Reduced space below the rating */
}

.rating .star {
    color: #ccc; /* Default star color */
    font-size: 18px; /* Reduced star size */
}

.rating .star.filled {
    color: gold; /* Color for filled stars */
}

.testimonial-text {
    text-align: justify; /* Justify text alignment */
    width: 100%; /* Full width for text */
    white-space: normal; /* Allow text wrapping */
    word-wrap: break-word; /* Ensure long words break */
    line-height: 1.4; /* Adjusted line height for readability */
    font-size: 0.9em; /* Adjusted text size */
}



@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* For tablets and smaller screens (768px and below) */
@media (max-width: 768px) {
    .testimonial-card {
        flex: 0 0 220px; /* Further reduced the card width */
        padding: 12px; /* Reduced padding */
        margin: 5px; /* Reduced margin */
    }

    .testimonial-image {
        width: 60px; /* Adjusted image size */
        height: 60px; /* Adjusted height */
    }

    .testimonial-card h3 {
        font-size: 1.1em; /* Adjusted heading size */
    }

    .rating .star {
        font-size: 16px; /* Reduced star size */
    }
}

/* For mobile screens (480px and below) */
@media (max-width: 480px) {
    .testimonial-card {
        flex: 0 0 160px; /* Further reduce the card width */
        padding: 8px; /* Reduced padding */
        margin: 5px; /* Reduced margin */
    }

    .testimonial-image {
        width: 50px; /* Adjusted image size */
        height: 50px; /* Adjusted height */
    }

    .testimonial-card h3 {
        font-size: 0.9em; /* Further adjust heading size */
    }

    .rating .star {
        font-size: 14px; /* Further reduced star size */
    }

    .testimonial-text {
        font-size: 0.8em; /* Adjusted text size */
    }
}

/* ================================================================================================================================ */
#footer {
    color: #fff;
    padding: 50px 0 20px; /* Reduced top and bottom padding */
    background: linear-gradient(to right, #00215e, #2c4e80, #00215e);
    text-align: center;
}

.footer-logofooter {
    margin-bottom: 20px; /* Reduced bottom margin */
}

.footer-logofooter h2 {
    font-size: 45px;
    color: #fc4100;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.footer-logofooter p {
    font-size: 20px;
    color: #ffffff;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px; /* Reduced side padding */
    flex-wrap: wrap;
}

.footer-logo1,
.footer-links,
.footer-contact {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 380px; /* Slightly reduced max width */
    padding: 15px; /* Reduced padding */
    text-align: left;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 5px; /* Reduced margin */
}

.footer-logo1 p {
    font-size: 16px;
    line-height: 1.5; /* Slightly reduced line-height */
    color: #fff;
    margin-bottom: 15px; /* Reduced bottom margin */
}

.social-icons {
    display: flex;
    gap: 10px; /* Reduced gap between icons */
    justify-content: center;
    margin-top: 10px; /* Reduced margin */
}

.social-icons a {
    display: inline-block;
    width: 35px; /* Adjusted size */
    height: 35px; /* Adjusted size */
    background-color: #333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.social-icons a img {
    width: 30px; /* Adjusted size */
    height: 30px; /* Adjusted size */
}

.social-icons a:hover {
    background-color: #fc4100;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0 0; /* Reduced margin */
}

.footer-links li {
    margin-bottom: 8px; /* Reduced bottom margin */
    color: #fff;
}

.footer-contact p {
    margin: 2px 0; /* Reduced margin */
    display: flex;
    align-items: center;
    color: #fff;
}

.footer-contact i {
    margin-right: 8px; /* Reduced icon margin */
}

.footer-bottom {
    text-align: center;
    margin-top: 30px; /* Reduced margin */
    padding-top: 15px; /* Reduced padding */
    border-top: 1px solid #943509;
    font-size: 14px;
    color: #ffffff;
}

@media (max-width: 800px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-logo1,
    .footer-links,
    .footer-contact {
        max-width: 100%;
        margin-bottom: 0px;
        width: 90%;
        justify-content: center;
    }

    .footer-logo1 p,
    .footer-links li,
    .footer-contact p {
        font-size: 14px; /* Adjusted for smaller screens */
    }

    .social-icons {
        margin: 15px 0; /* Adjusted margin */
    }
}

@media (max-width: 480px) {
    .footer-content {
        padding: 0 10px; /* Reduced padding on smaller screens */
    }

    .footer-logofooter h2 {
        font-size: 28px; /* Further adjusted for very small screens */
    }

    .social-icons {
        flex-wrap: wrap; /* Wrap social icons on small screens */
        justify-content: center; /* Center the icons */
    }

    .social-icons a {
        width: 30px; /* Adjust icon size for small screens */
        height: 30px; /* Adjust icon size for small screens */
    }
}

/* ================================================================================================================================ */

/* Header and Navigation */
@media (max-width: 1024px) {
body.light-mode .nav {
background-color: #ffc55a;
}}

body.light-mode .nav ul li a,
body.light-mode .guest-btn,
body.light-mode .menu-toggle {
    color:#ffffff;
}


.light-mode .auth-buttons button {
    border: 1px solid #ffffff;
    color: #ffffff;
}

.light-mode .login-btn:hover,
.light-mode .guest-btn:hover,
.light-mode .signup-btn:hover {
    border: 1px solid #fc4100;
    color: #fc4100;
}


/* Gender Equality Section */
.light-mode .gender-equality-section {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
          );
          color: black;
}
.light-mode .genderdescription {
    color: rgb(0, 0, 0);
    }
    .light-mode .profile-text {
        color: rgb(0, 0, 0);
        }
    
/* Our Mission Section */
.light-mode #our-mission {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
    );
    color: black;
}

.light-mode #benefits {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
    );
    color: black;
}
/* Benefits Section */
.light-mode .benefits-container-outside {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
    );
    color: black;
}


/* Our Approach Section */
.light-mode .approach-description {
color: black;
}

.light-mode .our-approach {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
    );
    color: black;
}

.light-mode .approach-card {
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.light-mode .card-description {
    color: black;
}


.light-mode .card-link:hover {
    color: #006666;
}

/* Features Section */
.light-mode #features {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
    );
    color: black;
}

.light-mode .feature-card {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.432);
    color: #333333;
}

.light-mode .feature-icon {
    color: #008c8c;
}

/* Testimonials Section */
.light-mode #testimonials {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
    );
    color: black;
}

.light-mode .testimonial-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 8px 22px rgba(0, 140, 140, 0.206);
}

.light-mode .testimonial-card .title {
    color: #666666;
}

.light-mode .rating .star.filled {
    color: #ece405;
}


/* Footer */
.light-mode #footer {
    background: linear-gradient(
        to right,
        #ffc55a,
        #ffc55a
          );
          color: black;
}

.light-mode .footer-logofooter h2 {
    color: #00215e; /* Darker color for light mode */
    text-shadow: none; /* Remove text shadow for light mode */
}

.light-mode .footer-logofooter p,
.light-mode .footer-logo1 p,
.light-mode .footer-links li,
.light-mode .footer-contact p {
    color: #333; /* Darker text for light mode */
}



.light-mode .footer-bottom {
    color: #333; /* Darker footer-bottom text */
    border-top: 1px solid #ccc; /* Lighter border in light mode */
}
