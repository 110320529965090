/* Main container */
.double-sidebar-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  /* transition: width 0.3s, background-color 0.3s, color 0.3s; */
  /* overflow: hidden;*/
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  width: 30%;
  z-index: 1000;
}

/* Left Sidebar */
.left-sidebar {
  width: 60px;
  transition: width 0.3s ease;
  overflow: hidden;
  background-color: white; /* Fixed */
  display: flex;
  flex-direction: column;
  z-index: 2000;
}

/* Sidebar content */
.sidebar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

/* Sidebar bottom */
.sidebar-bottom {
  margin-top: auto;
  padding-bottom: 15px;
  border-top: 1px solid #e0e0e0; /* Light border */
}

/* Sidebar items */
.sidebar-item {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sidebar-item:hover {
  background-color: #f0f0f0; /* Light hover state */
}

.sidebar-item.active {
  background-color: #e0e0e0; /* Slightly darker active state */
}

.sidebar-item .icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.sidebar-item .item-text {
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-sidebar.expanded .sidebar-item .item-text {
  display: inline;
}

/* Sidebar expand button (now integrated as a sidebar item) */
.sidebar-item.expand {
  justify-content: center;
}

.sidebar-item.expand .icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.left-sidebar.expanded .sidebar-item.expand .icon {
  transform: rotate(180deg);
}

/* Right Sidebar */
.right-sidebar {
  width: 100%;
  background-color: #d0cfce; /* Fixed */
  border-left: 1px solid #e0e0e0; /* Light border */
  padding: 20px;
  overflow-y: auto;
  z-index: 200;
  display: flex;
  flex-direction: column; /* Set to column */
}

.right-sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.right-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.right-sidebar li {
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.right-sidebar li:hover {
  background-color: #b5b2b2; /* Slightly darker hover state */
  color: #737476;
}


/* =================================================================================================================================================================================================== */
/* Detailed content section */
.detailed-content {
  display: flex;
  flex-direction: column; /* Maintain vertical layout for content */
}

.content-section {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px; /* Space between sections */
}

.headersidebar {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Space between heading and content */
}

.header-iconheadersidebar {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  margin-right: 10px; /* Space between icon and heading */
}

.avatar-placeholder {
  width: 30%; /* 30% space for avatar */
  height: 60px; /* Height of the placeholder, adjust as needed */
  background-color: #f0f0f0; /* Placeholder color */
  margin-right: 15px; /* Space between avatar and content */
  border-radius: 5px; /* Optional: rounded corners */
}

.detailed-content ul {
  list-style-type: none;
  padding: 0;

}

.detailed-content li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #aaaaaa; /* Light border */
}

.right-icon {
  margin-left: 10px;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}

.right-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  padding: 5px 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
  gap: 10px; /* Space between logo and heading */
}

/* Avatar container styles */
.sidebar-logo {
  width: 50px; /* Adjust size of the logo */
  height: auto;
}

.right-sidebar-header h2 {
  font-size: 1.2rem; /* Adjust heading font size */
  font-weight: 500;
  margin: 0; /* Remove default margin */
}

/* Avatar container styles */
.avatarcontainerr {
  width: 70%; /* Width of the container */
  height: auto; /* Auto height */
  margin: 0 auto; /* Center horizontally and space vertically */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: white; /* Fixed */
  border-radius: 10px; /* Rounded corners */
  padding: 0px; /* Optional: add padding for aesthetics */
}

.avatarcontainer1 {
  display: none; /* Hide by default */
}

@media (max-width: 800px) {
  .avatarcontainer1 {
    display: block; /* Show only on screens smaller than 800px */
    position: fixed; /* Make it fixed */
    top: 95px;
    right: 0px;
    width: 150px; /* Adjust width to make it smaller */
    height: auto; /* Adjust height to make it smaller */
    z-index: 2000; /* Ensure it's above other elements */
  }
}

@media (max-width: 400px) {
  .avatarcontainer1 {
    display: block; /* Show only on screens smaller than 800px */
    position: fixed; /* Make it fixed */
    top: 95px;
    right: 0px;
    width: 120px; /* Adjust width to make it smaller */
    height: auto; /* Adjust height to make it smaller */
    z-index: 2000; /* Ensure it's above other elements */
  }
}

.renderd {
  overflow-y: auto;
}

.subcategory-list {
  list-style-type: none;
  padding-left: 1rem;
  margin-left: 1rem;
}

.subcategory-list li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.subcategory-list li .avatar-thumbnail {
  width: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}

.subcategory-list li.active {
  font-weight: bold;
}
/* ============================================================================================================================================================================== */
/* Sample Questions Styles */
.sample-questions-toggle {
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease;
  text-align: left; /* Align the text to the left */
}

.sample-questions-toggle:hover {
  background-color: #e0e0e0;
  color: #737476;
}

.sample-questions-toggle.active {
  background-color: #d0d0d0;
  font-weight: bold;
}

.right-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

/* Sample questions list */
.sample-questions-list {
  margin-top: 10px;
  padding-right: 10px;
  margin-right: 10px;
  display: none;
  color: #121212;
  text-align: left; /* Align text to the left */
  text-align: justify; /* Justify text */
}

.sample-questions-list.visible {
  display: block;
}

/* Individual question buttons */
.question-button {
  display: block;
  padding: 8px 10px;
  margin: 5px 0;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #a4a4a4;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-align: left; /* Align button text to the left */
  text-align: justify; /* Justify button text */
}

.question-button:hover {
  background-color: #17b8ce;
}

/* Disabled state for question buttons */
.question-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  color: #999;
}

/* Active question button state */
.question-button.active {
  background-color: #d0e0f0;
  font-weight: bold;
}

/* =============================================================================================================================================================================== */
.favorites-section {
  margin: 0px;
}

.category-container {
  display: flex;
  flex-direction: column; /* Main categories in column layout */
  padding-top: 15px;
}

.category-item {
  padding: 0px 0;
  border-bottom: 2px solid #aaaaaa; /* Light border */
}

.category-item div {
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 4px;
  text-align: left;
  color: #fcfcfc;
}


.subcategory-list {
  list-style-type: none; /* Remove bullet points */
  padding-left: 20px; /* Indentation for subcategories */
  margin-top: 10px;
  border-radius: 4px;
  padding: 50px;
}

.subcategory-list li {
  margin-right: 10px;
  padding: 8px 15px;
  border: 1px solid #8d8d8d; /* Light gray border */
  border-radius: 4px;
  background-color: #aaaaaa; /* White background */
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
  text-align: justify;
}

.subcategory-list li:hover {
  background-color: #af9e9e; /* Light blue on hover */
}

/* ================================================================================================================================================================================================ */
/* Dark mode styles */
/* Dark mode styles */
body.dark-mode .left-sidebar {
  background-color: #00215e;
  color: #ffffff;
}

body.dark-mode .right-sidebar {
  background-color: #141c2a;
  color: #ffffff;
  border-left-color: #444444;
}

body.dark-mode .sidebar-item:hover {
  background-color: #1f2738; /* Updated for better contrast */
}

body.dark-mode .sidebar-item.active {
  background-color: #2b354b; /* Updated to match the darker tone */
}

body.dark-mode .sidebar-expand,
body.dark-mode .sidebar-bottom {
  border-color: #444444;
}

body.dark-mode .right-sidebar h2 {
  border-bottom: 3px solid #e0e0e0;
}

body.dark-mode .right-sidebar li:hover {
  background-color: #1f2738; /* Updated for consistency */
}

/* Dark mode styles */
body.dark-mode .right-sidebar-header {
  background-color: #1e2637; /* Updated for darker tone */
  border-color: #2a3348; /* Complementary color for borders */
}

body.dark-mode .right-sidebar-header h2 {
  color: #fff;
}

body.dark-mode .avatarcontainerr {
  background-color: #2a3348; /* Updated for darker consistency */
}

body.dark-mode .sample-questions-toggle {
  background-color: #1e2637; /* Updated for better contrast */
  border-color: #2a3348;
  color: #fff;
}

body.dark-mode .sample-questions-toggle:hover {
  background-color: #2a3348; /* Updated to align with darker tone */
}

body.dark-mode .sample-questions-toggle.active {
  background-color: #3b4660; /* Updated for better active state */
}

body.dark-mode .sample-questions-list {
  color: #ddd;
}

body.dark-mode .question-button {
  background-color: #1c2535; /* Updated for button background */
  border-color: #2a3348;
  color: #fff;
}

body.dark-mode .question-button:hover {
  background-color: #1f2738; /* Consistent hover state */
}

body.dark-mode .question-button:disabled {
  background-color: #2a3348;
  color: #888;
}

body.dark-mode .question-button.active {
  background-color: #2b354b; /* Updated for consistency */
}

body.dark-mode .category-item div {
  border-bottom-color: #2a3348;
  color: #ddd;
}

body.dark-mode .category-item div:hover {
  color: #4da6ff;
  background-color: #1e2637; /* Darker background on hover */
}

body.dark-mode .subcategory-list {
  background-color: #1c2535; /* Updated for darker consistency */
}

body.dark-mode .subcategory-list li {
  background-color: #1f2738; /* Matching with hover states */
  border-color: #2a3348;
  color: #ddd;
}

body.dark-mode .subcategory-list li:hover {
  background-color: #2b354b;
}

body.dark-mode .chat-history-container {
  background-color: #1c2535;
}

body.dark-mode .chat-history-heading {
  color: #ddd;
}

body.dark-mode .chat-user-id-box {
  background-color: #2a3348;
  color: #ddd;
}

body.dark-mode .chat-history-list li {
  background-color: #1e2637;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

body.dark-mode .chat-history-item {
  background-color: #1c2535;
  border-color: #2a3348;
  color: #ddd;
}

body.dark-mode .chat-history-item:hover {
  background-color: #2b354b;
  color: #4da6ff;
}

body.dark-mode .no-chat-message {
  color: #aaa;
  background-color: #1c2535;
}

body.dark-mode .category-item {
  border-bottom: 1px solid #ccc; /* Light border */
}
body.dark-mode .detailed-content li {
  border-bottom: 1px solid #ccc; /* Light border */
}

/* ============================================================================================================================================================================== */





@media screen and (max-width: 800px) {
  /* Main container */
  .double-sidebar-container {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: auto;
      flex-direction: column;
      transition: left 0.3s ease;
      z-index: 1000;
      background-color: #ffffff;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .double-sidebar-container.show-sidebar {
      left: 0;
  }

  /* Left Sidebar */
  .left-sidebar {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: flex-start;
      padding: 10px 0 10px 50px; /* Added left padding to avoid overlap with toggle */
      background-color: #ffffff;
      border-bottom: 1px solid #e0e0e0;
  }

  .sidebar-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      overflow-x: auto;
  }

  .sidebar-bottom {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
  }

  .sidebar-item {
      padding: 8px;
      margin: 0 5px;
      flex-shrink: 0;
  }

  .sidebar-item .icon {
      width: 24px;
      height: 24px;
      margin-right: 0;
  }

  .sidebar-item .item-text {
      display: none;
  }

  /* Right Sidebar */
  .right-sidebar {
      width: 100%;
      height: auto;
      max-height: calc(100vh - 60px); /* Maximum height, adjust based on your left sidebar height */
      overflow-y: auto;
      border-left: none;
      padding: 10px;
  }

  .right-sidebar-header {
      padding: 5px;
      margin-bottom: 10px;
  }

  .sidebar-logo {
      width: 30px;
      height: auto;
  }

  .right-sidebar-header h2 {
      font-size: 1rem;
  }

  /* Hide avatar container */
  .avatarcontainerr {
      display: none;
  }

  /* Detailed content */
  .detailed-content ul {
      padding-left: 10px;
  }

  .detailed-content li {
      padding: 8px 5px;
  }

  /* Sample questions */
  .sample-questions-toggle {
      padding: 8px 10px;
  }

  .sample-questions-list {
      margin-top: 5px;
  }

  .question-button {
      padding: 6px 8px;
      font-size: 0.9em;
  }

  /* Favorites section */
  .favorites-section {
      margin: 5px 0;
  }

  .category-item div {
      padding: 8px;
  }

  .subcategory-list {
      padding: 5px;
  }

  .subcategory-list li {
      padding: 6px 10px;
  }

  /* Chat history */
  .chat-history-container {
      padding: 10px;
  }

  .chat-history-heading {
      font-size: 1.2rem;
  }

  .chat-user-id-box {
      padding: 5px;
      font-size: 0.9rem;
  }

  .chat-history-item {
      padding: 8px 10px;
  }

  /* Toggle button */
  .sidebar-toggle {
      display: block;
      position: fixed;
      top: 10px;
      left: 10px;
      z-index: 1001;
      background-color: #f0f0f0;
      border: none;
      padding: 10px;
      cursor: pointer;
      font-size: 1.5em;
  }
}

@media screen and (max-width: 420px) {
  /* Left Sidebar */
  .left-sidebar {
    padding: 5px 0 5px 40px;
  }

  .sidebar-content {
    justify-content: center;
  }

  .sidebar-item {
    padding: 5px;
    margin: 0 3px;
  }

  .sidebar-item .icon {
    width: 20px;
    height: 20px;
  }

  /* Right Sidebar */
  .right-sidebar {
    padding: 5px;
  }

  .right-sidebar-header {
    padding: 3px;
    margin-bottom: 5px;
  }

  .sidebar-logo {
    width: 25px;
  }

  .right-sidebar-header h2 {
    font-size: 0.9rem;
  }

  /* Detailed content */
  .detailed-content li {
    padding: 6px 3px;
    font-size: 0.9em;
  }

  /* Sample questions */
  .sample-questions-toggle {
    padding: 6px 8px;
    font-size: 0.9em;
  }

  .question-button {
    padding: 5px 6px;
    font-size: 0.8em;
  }

  /* Favorites section */
  .category-item div {
    padding: 6px;
    font-size: 0.9em;
  }

  .subcategory-list li {
    padding: 5px 8px;
    font-size: 0.8em;
  }

  /* Chat history */
  .chat-history-heading {
    font-size: 1rem;
  }

  .chat-user-id-box {
    padding: 3px;
    font-size: 0.8rem;
  }

  .chat-history-item {
    padding: 6px 8px;
    font-size: 0.9em;
  }

  /* Toggle button */
  .sidebar-toggle {
    top: 5px;
    left: 5px;
    padding: 8px;
    font-size: 1.2em;
  }
}


/* ==================================================================================================================================================================== */

.right-sidebar li:hover {
  background-color: #fefeff; /* Updated for consistency */
  color: black;
  font-style: bold;
}


 .category-item div {
  color: #000000;
  font-style: bold;
}

.category-item div:hover {
  background-color: #fefeff; /* Updated for consistency */
}

.subcategory-list li {
  color: #000000;
}

