/* src/Component/Signup.css */
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .signup-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .signup-form h2 {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .signup-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .signup-form button {
    width: 100%;
    padding: 0.5rem;
    background-color: #42b72a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .signup-form button:hover {
    background-color: #36a420;
  }
  
  .switch-link {
    color: #1877f2;
    cursor: pointer;
  }
  
  .switch-link:hover {
    text-decoration: underline;
  }