/* Chat Header Styles */
.occ-chat-header {
  color: white;
  text-align: center;
  padding: 50px 10px 20px 10px;
  border-bottom: 2px solid #b0dce8;
  margin-bottom: 2px;
  margin-top: 50px;
}

/* Gradient Animation for Header Text */
.occ-chat-header h3,
.occ-chat-header h4 {
  font-size: 45px; /* Adjust size as needed */
  font-weight: 600;
  margin: 10px 0 0;
  text-align: left; /* Center align the text */
  background: linear-gradient(270deg, #0d239d, #dd5500, #ddff00);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Hide default text color */
  background-size: 300% 300%;
  animation: gradientShift 6s ease infinite;
  padding-left: 32%;
}

.occ-chat-header h4 {
  font-size: 35px; /* Slightly smaller font size for h4 */
}

/* Gradient Animation */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media (max-width: 1100px) {
  .occ-chat-header {
    padding-left: 32%;
  }
  .occ-chat-header h3,
.occ-chat-header h4 {
 padding-left: 0; 
}
}

/* Responsive adjustments */
@media (max-width: 800px) {
  .occ-chat-header {
    padding-left: 2%;
  }
  .occ-chat-header h3,
  .occ-chat-header h4 {
    text-align: left;
    font-size: 33px; /* Adjust size as needed */
    font-weight: 600;
  }
}

@media (max-width: 608px) {
  .occ-chat-header h3,
  .occ-chat-header h4 {
    font-size: 26px; /* Adjust size as needed */
    font-weight: 600;
  }
}

@media (max-width: 400px) {
  .occ-chat-header {
    padding-top: 30%;
  }
  .occ-chat-header h3,
  .occ-chat-header h4 {
    font-size: 24px; /* Adjust size as needed */
    font-weight: 600;
  }
}

/* =================================================================================================================================================00 */

/* Options Container */
.options-container1 {
  display: flex;
  justify-content: center; /* Align cards to the left */
  flex-wrap: nowrap; /* Prevent wrapping of cards */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 20px 10px;
  gap: 20px; /* Increased space between options */
  margin: 0 20px; /* Equal space on both sides */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
  padding-top: 250px;
  /* font-family: 'JameelNooriNastaleeqKasheeda', sans-serif; */
}

/* Scrollbar styles for WebKit browsers */
.options-container1::-webkit-scrollbar {
  height: 8px;
}

.options-container1::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.options-container1::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.options-container1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Individual Option Card */
.option {
  flex: 0 0 180px; /* Increased width for each card */
  height: 180px; /* Increased height for each card */
  cursor: pointer;
  border-radius: 5px; /* Increased border radius */
  overflow: hidden;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Option Image */
.option-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}

/* Zoom Effect on Hover */
.option:hover .option-image {
  transform: scale(1.1);
}

/* Option Text */
.option-text {
  position: absolute;
  color: white;
  padding: 10px;
  border-radius: 5px;
  bottom: 70px;
  left: 10px;
  width: calc(100% - 20px);
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  opacity: 1;
  /* text-decoration: underline; */
  text-decoration-thickness: 2px;
  white-space: normal;
  overflow: hidden;
  transition: opacity 0.3s, background 0.3s, bottom 0.3s;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.6);
}

.option-description {
  position: absolute;
  color: white;
  padding: 10px;
  border-radius: 5px;
  bottom: 40px;
  left: 10px;
  width: calc(100% - 20px);
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: opacity 0.3s, visibility 0.3s, bottom 0.3s;
  background-color: rgba(0, 0, 0, 0.6);
}

.option-buttons {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: none;
  gap: 10px;
}

.option:hover .option-text {
  opacity: 0;
  bottom: 110px;
}

.option:hover .option-description {
  opacity: 1;
  visibility: visible;
  bottom: 70px;
}

.option:hover .option-buttons {
  display: flex;
}

.option-button {
  background: #e0e0e0;
  border: none;
  color: #333;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s, transform 0.3s;
}

.option-button:hover {
  background: #c0c0c0;
  transform: scale(1.05);
}



@media (max-width: 868px) {
  .chat-header h3,
  .chat-header h4 {
    font-size: 33px; /* Adjust size as needed */
    font-weight: 600;
    padding-bottom: 20px;
  }

  .options-container1 {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Ensure snapping to each card */
    gap: 10px;
   
    margin: 0;
  }

  .option {
    flex: 0 0 50%; /* Ensure each card takes up 90% of the screen width */
    height: 160px;
    scroll-snap-align: center; /* Center the card when it scrolls into view */
    /* padding-right: 10px; */
  }

  .option:first-child {
    margin-left: 56%; /* No additional margin on the first card */
  }

  .option-text {
    font-size: 14px;
  }

  .option-description {
    font-size: 11px;
  }

  .option-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
@media (max-width: 608px) {
  .chat-header h3,
  .chat-header h4 {
    font-size: 26px; /* Adjust size as needed */
    font-weight: 600;
    padding-bottom: 20px;
  }
}


@media (max-width: 400px) {
  .chat-header {
    padding-left: 13%;
  }
  .chat-header h3,
  .chat-header h4 {
    font-size: 24px; /* Adjust size as needed */
    font-weight: 600;
  }
  .options-container1 {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Ensure snapping to each card */
    gap: 10px;
   
    margin: 0;
  }

  .option {
    flex: 0 0 90%; /* Ensure each card takes up 90% of the screen width */
    height: 160px;
    scroll-snap-align: center; /* Center the card when it scrolls into view */
    /* padding-right: 10px; */
  }

  .option:first-child {
    margin-left: 180%; /* No additional margin on the first card */
  }
}

/* =================================================================================================================================================00 */
/* =================================================================================================================================================00 */

body.dark-mode .option:hover .option-image {
  filter: blur(4px);
}

body.dark-mode .option:hover .option-text {
  color: #ffffff;
}

body.dark-mode .option-text,
body.dark-mode .option-description {
  color: #ffffff;
}
