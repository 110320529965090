.headerchatbot {
  position: fixed; /* Fix the header to the top of the page */
  top: 0;
  right: 0;
  z-index: 100; /* Ensure the header stays above other content */
  display: flex;
  background-color: #d0cfce;
  justify-content: space-between; /* Space between items */
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #cec3d8;
  width: 100%; /* Set the width of the header */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: background-color 0.3s ease-in-out; /* Smooth transition for background color */
}

.header-left {
  display: flex;
  align-items: center;
  margin-left: 30%;
}

.header-right {
  display: flex;
  align-items: center;
  margin-left: auto; /* Move both the mode buttons and exit button to the right */
}

.logo-name {
  font-size: 24px;
  font-weight: 700;
  color: white;
  opacity: 0;
  animation: fadeInLogo 1s forwards; /* Fade-in effect */
}
.chatbotheader-logo {
  width: 40px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between logo and name */
}


@keyframes fadeInLogo {
  0% {
    opacity: 0;
    transform: translateY(-10px); /* Slight upward movement */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Reset position */
  }
}

.exit-button {
  background: none; /* Remove background color */
  border: none;
  cursor: pointer; /* Pointer cursor on hover */
  margin-left: 20px; /* Spacing between buttons */
  transition: transform 0.3s ease-in-out; /* Smooth transition on hover */
}

.exit-button:hover {
  transform: rotate(90deg); /* Rotate the exit icon on hover */
}

.exit-icon {
  width: 20px; /* Icon size */
  height: 20px; /* Icon size */
}

/* Mode buttons */
.mode-button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 0; /* Remove default padding */
  margin-right: 10px; /* Space between buttons */
  transition: transform 0.2s, color 0.2s; /* Add smooth transitions for both transform and color */
}

.mode-button:hover {
  transform: scale(1.2); /* Scale the button slightly on hover */
  color: #ffccbc; /* Lighten color on hover */
}

.mode-icon {
  width: 20px; /* Adjust icon size as needed */
  height: 20px; /* Adjust icon size as needed */
}

/* Dark mode styling */
body.dark-mode .headerchatbot {
  background-color: #141c2a; /* Dark background for header in dark mode */
  border-bottom: 1px solid #292828; /* Darker border for dark mode */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Stronger shadow in dark mode */
}

body.dark-mode .logo-name {
  color: #e0e0e0; /* Light color for logo name in dark mode */
}

body.dark-mode .mode-button:hover {
  color: #ffd700; /* Golden tint on hover in dark mode */
}

/* Responsive adjustments */
@media (max-width: 800px) {
  .logo-name {
    font-size: large;
  }

  .header-left {
    margin-left: 10%;
  }
}

@media (max-width: 300px) {
  .logo-name {
    display: none; /* Hide the logo name */
  }
}
