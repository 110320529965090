/* Overlay for the popup */
.popup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for a cleaner look */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(6px); /* Blurred background for a modern feel */
}

/* Popup form content */
.popup-form-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 450px;
  position: relative;
  z-index: 1001;
  text-align: center;
  animation: popup-appear 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) both;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.popup-form-content:hover {
  transform: scale(1.02); /* Slightly scales up on hover */
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.25); /* Deeper shadow */
}

/* Animation for popup appearance */
@keyframes popup-appear {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Form elements */
.popup-form-content h3 {
  margin-bottom: 25px;
  font-size: 26px;
  font-weight: bold;
  color: #333;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.popup-form-content label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #444;
}

.popup-form-content input,
.popup-form-content select,
.popup-form-content textarea {
  width: 100%;
  padding: 14px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

.popup-form-content input:hover,
.popup-form-content select:hover,
.popup-form-content textarea:hover {
  border-color: #007bff; /* Color change on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.popup-form-content input:focus,
.popup-form-content select:focus,
.popup-form-content textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 6px 15px rgba(0, 123, 255, 0.3); /* Adds a blue glow on focus */
}

/* Button Styles */
.popup-form-content button {
  padding: 14px 30px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: inline-block;
}

.popup-form-content button:hover {
  background-color: #0056b3;
  box-shadow: 0 8px 20px rgba(0, 91, 187, 0.2); /* Adds shadow on hover */
}

.popup-form-content button:active {
  background-color: #003f7f;
  box-shadow: none; /* Removes shadow on click */
  transform: scale(0.98); /* Button slightly shrinks on click for feedback */
}

/* Textarea for additional info */
.popup-form-content textarea {
  resize: vertical;
  min-height: 100px;
}

/* Input error feedback */
.popup-form-content input:invalid,
.popup-form-content select:invalid,
.popup-form-content textarea:invalid {
  border-color: #ff4d4d;
  /* background-color: #ffe6e6; */
}

.popup-form-content input:invalid:focus,
.popup-form-content select:invalid:focus,
.popup-form-content textarea:invalid:focus {
  border-color: #ff0000;
  box-shadow: 0 6px 15px rgba(255, 0, 0, 0.2); 
}

/* Remove close button */
.popup-close {
  display: none !important; /* Ensure the close button is hidden */
}
