.avatar-page {
  display: flex;
  justify-content: flex-start; /* Changed from center to flex-start */
  align-items: center;
  position: relative;
  top: 25%;
  transform: translateY(-30%);
  width: auto;
  height: auto; /* Set a height to ensure full vertical coverage */
}

.avatar-img {
  max-width: 100%;
  max-height: auto;
  object-fit: contain;
  padding-left: 0; /* Removed left padding */
  /* background-color: red; */
}
