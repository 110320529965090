/* General Styles */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: white;
}

.content-container {
  flex: 1; /* Take up available space */
  padding-bottom: 50px; /* Add space for the footer */
  /* overflow-x: auto; Enable horizontal scrolling if needed */
  scrollbar-width: thin;
}

.chat-content {
  overflow-y: auto;
  max-height: 100%; /* Adjust based on your layout */
  scrollbar-width: thin;
  padding-bottom: 90px; 
  /* direction: rtl; Set text direction for the entire body */
  /* font-family: 'JameelNooriNastaleeqKasheeda', sans-serif; */
}

.chatbot-container {
  position: relative;
  width: 68%; /* Keep the container responsive */
  max-width: 1100px; /* Max width for large screens */
  height: 90vh;
  background-size: cover;
  background-position: center;
  display: flex;
  right: 0;
  flex-direction: column;
  margin-left: auto; /* Align the container to the right */
  margin-top: 10px; /* Adjust as needed to fit your layout */
  background-color: white;
  scrollbar-width: thin;
  margin-right: 10px;
}

/* Option Text */
.option-text {
  color: white; /* Light mode text color */
  padding: 5px;
  text-align: center;
  font-size: 1rem;
}

/* Chat Window */
.chat-window {
  padding-top: 60px; /* Add padding to the top of the main content */
  position: relative; /* Ensure the query form is positioned relative to the chat window */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 850px; /* Max width for large screens */
  margin: 0 auto; /* Center horizontally */
  overflow-y: auto; /* Enable vertical scrolling for chat content */
}

/* Message Bubble */
.message-bubble {
  border-radius: 15px;
  background-color: #e1e3e4;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  max-width: 90%;
  position: relative; /* Ensure positioning context for stars */
  padding-bottom: 1px;
  /* direction: rtl; Set text direction for the entire body */
  
}

/* User-specific styles */
.message-bubble.user {
  background-color: #d0cfce;
  animation: gradientText 4s ease infinite; /* Animation */
  padding: 10px 20px; /* Ensure padding is consistent */
  align-self: flex-end;
  text-align: left;
}

/* Gradient Text Animation */
@keyframes gradientText {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Additional styling for other senders (if needed) */
.message-bubble.other {
  /* Other styles specific to other senders */
}

.message-bubble.bot {
  color: rgb(40, 39, 39); /* Light mode text color */
  text-align: left;
  text-justify: auto;
  background-color: #d0cfce;
}

/* Message Header */
.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.message-title {
  font-weight: bold;
  margin-left: 0rem;
}

.message-text {
  font-size: 1.1rem;
}


.query-form {
  position: fixed; /* Position it inside the chat window */
  bottom: 10px; /* Stick it to the bottom of the chat window */
  right: 10px; /* Fix this as 'px' for proper spacing */
  border: 1px solid #646464; /* Lighter border for better visibility */
  background-color: #ffffff; /* Light mode background color */
  border-radius: 35px;
  display: flex;
  align-items: center;
  max-width: 100%;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); Subtle shadow for depth */
  /* font-family: 'JameelNooriNastaleeqKasheeda', sans-serif; */
  width: 68%; /* Keep the container responsive */
  max-width: 1100px; /* Max width for large screens */
  margin: 0 auto;
  margin-right: 10px;
}
@media (max-width: 1500px) {
  .query-form {
    max-width: 68%; /* For medium screens, make it 90% of the screen width */
  }
}

@media (max-width: 1200px) {
  .query-form {
    max-width: 65%; /* For medium screens, make it 90% of the screen width */
  }
}

@media (max-width: 800px) {
  .query-form {
    width: 100%; /* Keep the container responsive */
    max-width: 95%; /* For smaller screens, make it 95% of the screen width */
    right: 0; /* Adjust right margin to ensure proper alignment */
  }
}

@media (max-width: 480px) {
  .query-form {
    max-width: 95%; /* For very small screens, make it almost full width */
   
  }
}



.input-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 10px;
}

.reactive-fluid {
  position: absolute;
  left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.reactive-fluid.idle {
  background-color: #ccc;
}

.reactive-fluid.thinking {
  background-color: #ffcc00;
  animation: pulse 1.5s infinite;
}

.reactive-fluid.speaking {
  background-color: #33cc33;
  animation: wave 1.5s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

@keyframes wave {
  0% { height: 8px; }
  50% { height: 16px; }
  100% { height: 8px; }
}

.query-input {
  width: 100%;
  padding: 12px 80px 12px 25px;
  border-radius: 25px;
  border: 2px solid #e0e0e0;
  background-color: #ffffff;
  color: #333;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.query-input:focus {
  /* border-color: #337cbf; */
}

.query-input::placeholder {
  color: #ffffff;
}

.query-input:disabled {
  background-color: #ffffff;
  cursor: not-allowed;
}

.voice-input-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.voice-input-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mic-icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
}

.mic-icon.listening {
  filter: invert(45%) sepia(90%) saturate(2000%) hue-rotate(180deg) brightness(100%) contrast(95%);
  animation: pulse 1.5s infinite;
}

.send-button {
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  background-color: #337cbf;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 2px;
}

.send-button:hover:not(:disabled) {
  background-color: #286a9d;
}

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.send-button::before {
  margin-right: 5px;
}






/* ... (Keep the existing CSS for larger screens) ... */

/* Query Form */


/* ---------------------------------------------------------------------------------================================================================================ */


@media (max-width: 600px) {
  .query-form {
    border-radius: 0;
    border-top: 1px solid #646464;
    padding: 5px 10px;
  }

  .input-container {
    margin-right: 5px;
  }

  .query-input {
    padding: 8px 60px 8px 10px;
    font-size: 14px;
    border-radius: 15px;
  }

  .voice-input-button {
    right: 35px;
  }

  .mic-icon {
    width: 18px;
    height: 18px;
  }

  .send-button {
    padding: 10px;
    font-size: 14px;
    border-radius: 10%;
    width: 60px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .send-button::before {
    margin-right: 0;
  }
}













/* Bot Logo */
.bot-logo {
  width: 2.1875rem; /* 35px converted to rem */
  height: 2.1875rem; /* 35px converted to rem */
  border-radius: 50%;
  margin-right: 8px;
}

/* =================================================================================================== */
/* Voice Controls */
.voice-controls {
  display: flex; /* Make the container a flex container */
  align-items: center; /* Vertically center align items */
  justify-content: space-between; /* Space between child elements */
  margin-top: 15px; /* Add some space above the controls */
  padding-bottom: 3px;
}

/* Like/Dislike Controls */
.like-dislike-controls {
  display: flex; /* Arrange buttons in a row */
  gap: 13px; /* Space between the buttons */
  padding-left: 25px;
}

/* Like/Dislike Buttons */
.like-button,
.dislike-button {
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
  padding: 0; /* Remove padding */
  width: 18px; /* Width for better touch target */
  height: 18px; /* Height for better touch target */
  display: flex; /* Flex container for centering */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  border-radius: 50%; /* Make buttons circular */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}


.like-button:active,
.dislike-button:active {
  transform: scale(1.5); /* Slightly shrink on click */
}

/* Icons */
.like-icon,
.dislike-icon {
  width: 25px; /* Set width for icons */
  height: 25px; /* Set height for icons */
  transition: transform 0.3s; /* Smooth icon transition */
}

/* Right Controls */
.right-controls {
  display: flex; /* Flex container for right controls */
  align-items: center; /* Vertically center align items */
}

/* Play Button */
.play-button {
  background-color: transparent; /* Transparent background */
  border: none; /* No border */
  font-size: 12px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  margin-left: 8px; /* Space from the previous button */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background transition */
}

.play-button img {
  width: 21px; /* Adjust the width of the image */
  height: 21px; /* Adjust the height of the image */
}

.play-button.playing {
  background-color: #828080; /* Change color when playing */
}

/* Voice Selector Button */
.voice-selector-button {
  background-color: #6fa6e2; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 4px 8px; /* Smaller size */
  font-size: 12px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  margin-left: 8px; /* Space from the previous button */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background transition */
}

.voice-selector-button:hover {
  background-color: #5d94d0; /* Darker shade on hover */
}

/* Modal Styles */
.voice-modal-overlay {
  position: fixed; /* Fixed position */
  top: 0; /* Top alignment */
  left: 0; /* Left alignment */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Flex for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 20000; /* High stacking order */
}

.voice-modal-content {
  background: white; /* White background */
  border-radius: 4px; /* Rounded corners */
  padding: 16px; /* Padding inside modal */
  max-width: 90%; /* Maximum width */
  max-height: 80%; /* Maximum height */
  overflow-y: auto; /* Scroll if content overflows */
  scrollbar-width: thin; /* Thin scrollbar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

/* Voice List Styles */
.voice-list {
  display: flex; /* Flex container */
  flex-direction: column; /* Vertical layout */
}

.voice-option.selected {
  background-color: #6e99c7; /* Selected option color */
  color: white; /* Selected option text color */
}

.voice-option {
  padding: 5px; /* Padding for options */
  cursor: pointer; /* Pointer cursor */
  border: none; /* No border */
  background: none; /* No background */
  text-align: left; /* Left align text */
  border-radius: 4px; /* Rounded corners */
}

.voice-option:hover {
  background-color: #f0f0f0; /* Hover background color */
}

/* Close Button */
.close-voice-modal {
  position: absolute; /* Absolute position */
  top: 8px; /* Top spacing */
  right: 8px; /* Right spacing */
  background: none; /* No background */
  border: none; /* No border */
  font-size: 18px; /* Font size */
  cursor: pointer; /* Pointer cursor */
}

/* Rating */
.rating {
  padding-top: 3px;
  display: flex; /* Enable flexbox for layout */
  align-items: center; /* Vertically center items */
  justify-content: flex-start; /* Align children to the left */
}

.rating .star {
  font-size: 20px; /* Adjust size as needed */
  color: rgb(114, 113, 113); /* Default color for unfilled stars */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: color 0.3s; /* Smooth transition for color change */
}


.rating .star.filled {
  color: rgb(255, 219, 12); /* Color for filled stars */
}

.rating .star:hover {
  color: rgb(208, 205, 0); /* Keep unfilled stars red on hover */
}


.rate-text {
  margin-right: 10px;
  font-size: 0.75rem;
}

/* Separator */
.separator {
  border-top: 1px solid #444;
  margin: 0;
}
/* ============================================================================================================== */
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  padding: 60px;
  margin-top: 60px;
  margin-top: 60px;
}
.loader::after,
.loader::before {
  content: '';
  width: 48px;
  height: 48px;
  border: 2px solid #747171;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2s ease-in-out infinite;
}
.loader::after {
  border-color: #12abed;
  animation-delay: 1s;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
/* =================================================================================================== */

.voice-input-button {
  background: none;
  border: none;
  right: 0px;
  cursor: pointer;
  padding: 0;
  margin-left: 0px;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.voice-input-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.voice-input-button:active {
  transform: scale(0.95);
}

.mic-icon-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic-icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
}

.voice-input-button.listening .mic-icon {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.listening-animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 2px solid #ff4136;
  animation: pulse 1.5s ease-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.voice-input-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.voice-input-button:disabled:hover {
  background-color: transparent;
}
/* =================================================================================================== */

.query-input::placeholder {
  transition: color 0.3s ease;
}
.query-input:disabled::placeholder {
  color: #999;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* =================================================================================================== */
/* =================================================================================================== */
/* Dark Mode Styles */

body.dark-mode {
  background-color: #00215e; /* Dark mode background color */
}
body.dark-mode .popup-content {
  background-color: #333; /* Dark mode popup content */
}
body.dark-mode .message-bubble {
  background-color: #141c2a;
}

body.dark-mode .message-bubble.bot {
  color: #ddd; /* Dark mode text color */
}

body.dark-mode .query-form {
  background-color: #202942; /* Dark mode background color */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Darker shadow for depth */
}

body.dark-mode .query-input {
  border: 1px solid #555; /* Darker border */
  background-color: #2c344a; /* Dark mode input background color */
  color: #ddd; /* Dark mode text color */
}

body.dark-mode .send-button {
  background-color: #555; /* Dark mode background color */
}

body.dark-mode .send-button:hover {
  background-color: #4f4d4d; /* Darker shade on hover */
}

body.dark-mode .send-button:active {
  background-color: #777; /* Even darker shade on click */
}

body.dark-mode .rating .star {
  color: #bbb; /* Darker color for stars */
}

body.dark-mode .rating .star.filled {
  color: #ffd700;
}

body.dark-mode .separator {
  border-top: 1px solid #666; /* Darker separator line */
}

body.dark-mode .bot-logo {
  filter: brightness(0.8); /* Slightly dim the logo */
}
/* Dark mode styling */
body.dark-mode .chat-footer {
  background-color: #2c344a; /* Dark background for footer in dark mode */
  color: #d0d0d0; /* Light text color for dark mode */
  border-top: 1px solid #2c344a; /* Darker border-top for dark mode */
}

body.dark-mode .chatbot-container{
  background-color: #00215e; /* Dark background for footer in dark mode */
  color: #d0d0d0; /* Light text color for dark mode */
}


@media screen and (max-width: 800px) {
  .chatbot-container {
    position: relative;
    width: 95%; /* Keep the container responsive */

  }
}

