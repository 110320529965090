.leave-site-container {
  position: fixed;
  bottom: 70px;
  right: 10px;
  z-index: 1000; /* Make sure it appears above other content */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
}

.leave-site-button {
  background-color: #fc4100; /* Red background */
  color: white;
  border: none;
  border-radius: 10px; /* Rounded edges */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.leave-site-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .leave-site-container {
    bottom: 8px; /* Adjust bottom spacing */
    right: 8px; /* Adjust right spacing */
  }

  .leave-site-button {
    padding: 8px 16px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
  }
}

@media (max-width: 480px) {
  .leave-site-container {
    bottom: 5px; /* Adjust bottom spacing */
    right: 5px; /* Adjust right spacing */
  }

  .leave-site-button {
    padding: 6px 12px; /* Adjust padding */
    font-size: 12px; /* Adjust font size */
  }
}
