/* Base styles for .App */

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500; /* Since this is the medium weight version */
  font-style: normal;
}

body {
  font-family: 'Roboto', sans-serif;
}


.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* Responsive styles */
@media (max-width: 600px) {
  .App-logo {
    height: 30vmin;
  }

  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 20px;
  }
}

.sidebar.full-width {
  width: 25vw;
}

.content-area {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.content-area.sidebar-expanded {
  margin-left: 25vw;
  width: 75vw;
}

.header.sidebar-expanded {
  padding-left: 25vw;
}

